import React, { useEffect, useState } from 'react';
import siteApi from '../api/siteApi';


export const useSiteDetails = (setLoading) => {
    const [siteDetails, setSiteDetails] = useState([]);
    const [siteID, setSiteID] = useState('');
    const [error, setError] = useState(false);

    const getSites = async () => {
        try {
            setError(false);
            setLoading(true);

            /**Call API */
            const params = {
                id: siteID
            };
            const dtRes = await siteApi.getDetails(params);
            console.log(dtRes);
            setSiteDetails(dtRes.data || []);

        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!siteID) {
            return;
        }

        getSites();
        return;
    }, [siteID])

    return { siteID, setSiteID, siteDetails };
}

