import axios from 'axios';
import Common from '../Common';

const axiosClient = axios.create({
    //baseURL: 'http://192.168.0.81:8082/api/staff',
    // baseURL: 'http://35.76.3.63/api/staff',  //old
    //baseURL: 'http://3.113.166.121/morien/api/staff', //new
    baseURL: 'http://shanai.morien-paint.com/api/staff', //new
    headers: {
        'Content-Type': 'application/json',
    }
})

// Interceptors
// Add a request interceptor
axiosClient.interceptors.request.use(
    function (config) {
        const { xUserID, xToken } = Common.localStorageGetToken();
        config.headers['X-UserID'] = xUserID;
        config.headers['X-Token'] = xToken;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        //TODO: Catch Error
        alert('エラーが発生します。');
        console.log(error.response);
        if (error.response.status === 401) {
            Common.localStorageRemoveToken();
            window.location.replace('/login');
        }
        return Promise.reject(error);
    }
);

export default axiosClient
