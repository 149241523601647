import { useEffect, useState } from 'react';
import goodsApi from '../api/goodsApi';

const initSearchTerm = {
    mode: 'Goods',
    hint: ''
}
export const useGoods = (setLoading, clientSelected) => {
    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [error, setError] = useState(false);
    const [listGoods, setListGoods] = useState([]);

    const getGoods = async () => {
        try {
            setError(false);
            setLoading(true);
            /**Call Api */
            const params = {
                hint: searchTerm.hint,
                clientID: clientSelected.clientID ?? 0
            }
            const dtResponse = await goodsApi.getAll(params);
            console.log(dtResponse.data);
            setListGoods(dtResponse.data.dtGoods || []);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getGoods();
        return;
    }, [searchTerm])

    return { searchTerm, setSearchTerm, listGoods };
}

