import axiosClient from "./axiosClient";

const salesSlipApi = {
    getAll(params) {
        const url = '/sales_slip';
        return axiosClient.get(url, { params });
    },

    getDetails(params) {
        const url = '/sales_slip/details';
        return axiosClient.get(url, { params });
    }
}

export default salesSlipApi
