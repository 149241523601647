import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color:beige;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const InputBox = styled.form`
    display:flex;
    width: 100%;
    justify-content: center;
    
    input{
        width: 70%;
        max-width: 500px;
        border-radius: 5px;
        font-size: var(--fontMed);
        padding: 5px;
        @media screen and (max-width: 768px){
        }
    }
    
    button{
        min-width: 70px;
        margin-left:3px;
        font-size: var(--fontMed);
        border:1px solid transparent;
        border-color: var(--morienGreen);
        border-radius: 5px;
        background-color: var(--morienGreen);
        color: var(--white);
        :hover{
            background-color:#0f0f0f;
            cursor: pointer;
        }
    }

`;
export const ClientName = styled.div`
    width: 100%;
    margin-left: 20px;
    margin-bottom: 3px;
    color:var(--morienGreen);
    font-weight:bold;
`;

export const FilterBox = styled.div`
    margin: 3px 16px;
    padding: 0;
    
`;
