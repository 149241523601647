import { useEffect, useState } from 'react';
import clientApi from '../api/clientApi';

const initSearchTerm = {
    mode: 'clients',
    hint: ''
}
export const useClients = (setLoading) => {
    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [error, setError] = useState(false);
    const [listClients, setListClients] = useState([]);

    const getClients = async () => {
        try {
            setError(false);
            setLoading(true);

            /**Call Api */
            const params = {
                hint: searchTerm.hint,
            }
            const dtResponse = await clientApi.getAll(params);
            console.log(dtResponse.data);
            setListClients(dtResponse.data.dtClient || []);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getClients();
        return;
    }, [searchTerm])

    return { searchTerm, setSearchTerm, listClients };
}

