import styled from 'styled-components';

export const Wrapper = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    /* width:100%; */
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
    font-size: var(--fontSmall);
        
    label{
        padding: 0 5px;
        color: #6c757d;
    }

`;

export const SiteInfo = styled.div`
   
`;