import styled from 'styled-components';

export const Wrapper = styled.div`
    a{
        display:flex;
        flex-direction: column;
        padding: 5px 5px;        
        border-bottom: 2px solid #ececec;
        text-decoration: none;
        color: var(--black);
        .title {
            color:var(--morienGreen);
            font-weight:bold;
        }
        :hover{
            background-color: #ececec;
            cursor:pointer;
        }
    }
`;