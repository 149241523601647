import { useEffect, useState } from 'react';
import salesSlipApi from '../api/salesSlipApi';

export const useSalesSlipDetails = (setLoading) => {

    const [salesSlipID, setSalesSlipID] = useState('');
    const [salesSlipDetails, setSalesSlipDetails] = useState({});
    const [error, setError] = useState(false);

    const getSalesSlipDetails = async () => {
        try {
            setError(false);
            setLoading(true);

            /**Call Api */
            const params = {
                id: salesSlipID,
            }
            const dtRes = await salesSlipApi.getDetails(params);
            console.log(dtRes);
            setSalesSlipDetails(dtRes.data);

        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!salesSlipID) {
            return;
        }

        getSalesSlipDetails();
        return;
    }, [salesSlipID])

    return { salesSlipID, setSalesSlipID, salesSlipDetails };
}

