import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction:column;

`;

export const Header = styled.div`
    padding: 5px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:600;
    
`;

export const GoodsList = styled.div`

    @media screen and (max-width: 768px){
        padding-bottom: 50px;
    }
`;