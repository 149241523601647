import styled from 'styled-components';

export const Wrapper = styled.div`
    .btn {
        cursor: pointer;
    }
`;

export const SHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:600;
    border-top: 3px solid var(--lightGreen);
`;

export const SalesGoods = styled.div`
    /* @media screen and (max-width: 768px){
        padding-bottom: 50px;
    } */
`;

export const GoodsInfo = styled.div`

`;