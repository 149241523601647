import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    flex-grow:1;
    overflow-y: scroll;
`;

export const SalesSlipInfo = styled.div`
    font-size:var(--fontSmall);
    margin-bottom:5px;
    label{
        font-size:var(--fontSSmall);
        padding: 0 5px;
        color: #6c757d;
    }
`;