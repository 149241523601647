import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

const SalesGoodsItem = ({ salseGoodsItem }) => {
    return (
        <Wrapper>
            <div className='item-title'>
                <span><label>品名：</label>{salseGoodsItem.GoodsName}</span>

            </div>
            <div>
                <span><label>数量：</label>{salseGoodsItem.Number}</span>
                <span className='pl-30'><label>サイズ：</label>{salseGoodsItem.GoodsSize}</span>
            </div>
            <div>
                <span><label>単価：</label>{Intl.NumberFormat('ja-JP').format(salseGoodsItem.Price)}円</span>
            </div>
        </Wrapper>
    )
}


SalesGoodsItem.propTypes = {
    salseGoodsItem: PropTypes.object,
}

SalesGoodsItem.defaultProps = {
    salseGoodsItem: {},
}

export default SalesGoodsItem
