import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderDetails from '../../components/HeaderDetails';
import { useSalesSlipDetails } from '../../hooks/useSalesSlipDetails';
import SalesGoodsList from './components/SalesGoodsList';

import { Wrapper, Content, SalesSlipInfo } from './styles';

const SalesSlipDetails = ({ setLoading }) => {
    const { salesslip_id } = useParams();
    const { salesSlipID, setSalesSlipID, salesSlipDetails } = useSalesSlipDetails(setLoading);

    useEffect(() => {
        setSalesSlipID(salesslip_id);
        return;
    }, [])

    /**Set DeliveryName */


    return (
        <Wrapper>
            <HeaderDetails mTitle='注文明細' subTitle='' alignTitle='center' />
            <Content>
                <SalesSlipInfo>
                    <div>
                        <span><label>受注番号：</label>{salesSlipDetails.SlipCodeAll}{salesSlipDetails.SlipCodeClient}</span>
                    </div>
                    <div>
                        <span><label>受注日：</label>{(salesSlipDetails.AcceptedDate || '--').substring(0, 10)}</span>
                    </div>
                    <div>
                        <span><label>お届け先：</label>{salesSlipDetails.SiteName || '--'}</span>
                    </div>
                    <div>
                        <span><label>納品スタッフ：</label>{salesSlipDetails.DeliveryStaffName || '--'}</span>
                    </div>
                    <div>
                        <span><label>納品日：</label>{(salesSlipDetails.DeliveryDate || '--').substring(0, 10) || '--'}</span>
                    </div>
                    <div>
                        <span><label>配送タイプ：</label>{salesSlipDetails.DeliveryType || '--'}　{salesSlipDetails.DeliveryTypeComment || '--'}</span>
                    </div>
                    <div>
                        <span><label>配送時間：</label>{salesSlipDetails.DeliveryTime || '--'}</span>
                    </div>
                    <div>
                        <span><label>配送状況：</label>{salesSlipDetails.Delivered != 1 ? '未' : '済'}</span>
                    </div>
                    <div>
                        <span><label>配送スタッフ：</label>{salesSlipDetails.DeliveryStaffName || '--'}</span>
                    </div>
                    <div>
                        <span><label>現場の住所：</label>{salesSlipDetails.SiteAddress || '--'}</span>
                    </div>
                    <div>
                        <span><label>メモ：</label>{salesSlipDetails.SalesSlipMemo || '--'}</span>
                    </div>
                </SalesSlipInfo>
                <SalesGoodsList salesGoodsList={salesSlipDetails.ListGoods || []} />
            </Content>
        </Wrapper>
    )
}

export default SalesSlipDetails
