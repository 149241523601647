import React, { useEffect } from 'react';
import SearchBox from '../../components/SearchBox';
import { useGoods } from '../../hooks/useGoods';
import GoodsItem from './components/GoodsItem';
import { Wrapper, Header, Content } from './styles';


const Goods = ({ setLoading, clientSelected }) => {
    const { searchTerm, setSearchTerm, listGoods } = useGoods(setLoading, clientSelected);
    const refTop = React.createRef();
    /**Scroll to Top */
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [listGoods])

    return (
        <Wrapper>
            <SearchBox setSearchTerm={setSearchTerm} textHolder='商品名前、コード' clientName={clientSelected.clientName} />
            <Content ref={refTop}>
                {listGoods.map((objGoods) => (
                    <GoodsItem
                        key={objGoods.GoodsID}
                        objGoods={objGoods}

                    />
                ))}
            </Content>
        </Wrapper>
    )
}

export default Goods;
