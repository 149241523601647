import axiosClient from "./axiosClient";

const clientApi = {
    /**
     * Get List Client
     * @param {page, limit} params 
     * @returns List of Clients
     */
    getAll(params) {
        const url = '/client';
        return axiosClient.get(url, { params });

    },


    getDetails(params) {
        const url = '/sales_slip';
        return axiosClient.get(url, { params });
    },
    /**
     * Get all SalesSlips of Client
     * @param {client_id} id 
     * @returns List SalesSlips of Client
     */
    getSalesSlip(id) {
        const url = `/clients/${id}`;
        return axiosClient.get(url);
    },

    /**
     * Get Info Client
     * @param {client_id} id 
     * @returns info of Client
     */
    getInfo(id) {
        const url = `/clients/${id}`;
        return axiosClient.get(url);
    }


}

export default clientApi
