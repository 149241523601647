import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './Router';
import NavMenu from "./components/NavMenu";
import Clients from './features/Clients';
import Goods from './features/Goods';
import SalesSlips from './features/SalesSlip';
import Sites from './features/Sites';
import Login from './features/Login';

import { GlobalStyle } from './GlobalStyles'
import { Wrapper } from './App.styles';
import Loading from './features/Loading';
import { useLoading } from './hooks/useLoading';
import ClientDetails from './features/ClientDetails';
import SiteDetails from './features/SiteDetails';
import GoodsDetails from './features/GoodsDetails';
import SalesSlipDetails from './features/SalesSlipDetails';
import GoodsSub from './features/GoodsSub';
import { useClientSelected } from './hooks/useClientSelected';


function App() {
    const { loading, setLoading } = useLoading();
    const { clientSelected, setClientSelected } = useClientSelected();
    return (
        <Wrapper>
            <Router>
                <Loading loading={loading} />
                <NavMenu clientSelected={clientSelected} setClientSelected={setClientSelected} />
                <Switch>

                    {/* ANCHOR: お客様 */}
                    <PrivateRoute
                        path={['/', '/clients']} exact
                        component={Clients}
                        setLoading={setLoading}
                        setClientSelected={setClientSelected}
                    />

                    {/* <PrivateRoute
                        path={['/clients/:clientID']} exact
                        component={ClientDetails}
                        setLoading={setLoading}
                    /> */}

                    {/* ANCHOR:　伝票 */}
                    <PrivateRoute
                        path={['/salesslips']} exact
                        component={SalesSlips}
                        setLoading={setLoading}
                        clientSelected={clientSelected}
                    />
                    <PrivateRoute
                        path={['/salesslips/:salesslip_id']} exact
                        component={SalesSlipDetails}
                        setLoading={setLoading}
                    />

                    {/* ANCHOR: 商品 */}
                    <PrivateRoute
                        path={['/goods']} exact
                        component={Goods}
                        setLoading={setLoading}
                        clientSelected={clientSelected}
                    />

                    <PrivateRoute
                        path={['/goods/:goods_id']} exact
                        component={GoodsDetails}
                        setLoading={setLoading}
                    />

                    <PrivateRoute
                        path={['/goods/:goods_id/sub']} exact
                        component={GoodsSub}
                        setLoading={setLoading}
                    />
                    {/* ANCHOR: 現在 */}
                    <PrivateRoute
                        path={['/sites']} exact
                        component={Sites}
                        setLoading={setLoading}
                        clientSelected={clientSelected}
                    />
                    <PrivateRoute
                        path={['/sites/:site_id']} exact
                        component={SiteDetails}
                        setLoading={setLoading}
                    />

                    {/* ANCHOR: ログイン */}
                    <PublicRoute
                        restricted={true}
                        path={['/login']} exact
                        component={Login}
                        setLoading={setLoading}
                    />

                </Switch>

                <GlobalStyle />
            </Router>
        </Wrapper>
    );
}

export default App;
