import styled from 'styled-components';

export const Wrapper = styled.div`
    a {
        display:flex;
        flex-direction: column;
        border-bottom: 2px solid #ececec;
        padding: 5px 5px;
        color: var(--black);
        text-decoration:none;
        
        span {
            font-size: var(--fontNormal);
        }
        .title {
            color:var(--morienGreen);
            font-weight:bold;
        }

        :hover{
            background-color: #ececec;
            cursor:pointer;
        }
    }
`;