import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import { Link } from 'react-router-dom';

const SiteItem = ({ objSite }) => {
    return (
        <Wrapper>
            <Link to={`/sites/${objSite.SiteID}`}>
                <span className='title'>{objSite.SiteName}</span>
                <small >〒{objSite.PostalCode || '－'}　{objSite.SiteAddress || '－'}</small>
                <small >お客様：{objSite.ClientName || '－'}</small>
                <div>
                    <small >開始日：{objSite.DateBeginning ? objSite.DateBeginning.slice(0, 10) : '－'}</small>
                    <small className='ml-30'>最終日：{objSite.DateFinished ? objSite.DateFinished.slice(0, 10) : '－'}</small>
                </div>
            </Link>
        </Wrapper>
    )
}

SiteItem.propTypes = {
    objSite: PropTypes.object.isRequired,
}

export default SiteItem;
