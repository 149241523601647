import { useState } from 'react';
const initClientSelected = {
    clientName: "",
    clientID: 0
}
export const useClientSelected = () => {
    const [clientSelected, setClientSelected] = useState(initClientSelected);

    return { clientSelected, setClientSelected };
}

