const TOKEN_KEY = 'user_info';
const TOKEN_LIFETIME = 1209600000;
const Common = {
    localStorageSetToken: (xUserID, xToken) => {
        const userInfo = {
            timeExpired: Date.now() + TOKEN_LIFETIME,
            xUserID: xUserID,
            xToken: xToken
        }
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
    },
    localStorageGetToken: () => {
        const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
        const returnInfo = {
            xUserID: '',
            xToken: ''
        }
        const timeCurrent = Date.now();
        const timeExpired = userInfo.timeExpired || 0;
        if (timeExpired > timeCurrent) {
            returnInfo.xUserID = userInfo.xUserID || '';
            returnInfo.xToken = userInfo.xToken || '';
        }

        return returnInfo;

    },
    localStorageRemoveToken: () => {
        window.localStorage.removeItem(TOKEN_KEY);
    }
}

export default Common
