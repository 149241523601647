import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, InputBox, FilterBox, ClientName } from './styles';


const SearchBox = ({ setSearchTerm, textHolder, filterTerm, setFilterTerm, clientName }) => {
    /**
     * ANCHOR: Object SearchTerm
     * const SearchTerm = {
     *      mode: 'clients' | 'sites' | 'sales_slips' | 'goods'| 'goodssub',
     *      hint: ''
     * }
    */
    const [dateNow, setDateNow] = useState(new Date());
    const [hint, setHint] = useState('');

    function handleSearch(e) {
        e.preventDefault();
        setSearchTerm(prev => ({
            ...prev,
            hint: hint
        }))
    }

    function handleFilter(event) {

        const newFilterTerm = {
            ...filterTerm,
            dateLimit: dateNow.getFullYear() + '-' + ('0' + (dateNow.getMonth() + 1)).substr(0, 2) + '-' + dateNow.getDate(),
            enable: event.target.checked,
        };
        setFilterTerm(newFilterTerm);
    }

    return (
        <Wrapper>
            <ClientName>
                {clientName}
            </ClientName>
            <InputBox onSubmit={handleSearch}>
                <input type='text'
                    placeholder={textHolder}
                    value={hint}
                    onChange={(e) => { setHint(e.target.value); }}
                />
                <button type='submit'>検索</button>
            </InputBox>
            {filterTerm ? (<FilterBox>
                <input id='id_checkbox' type='checkbox'
                    onChange={handleFilter} />
                <label htmlFor='id_checkbox'>「{dateNow.toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' })}」で有効データのみ表示<br /></label>
            </FilterBox>) : (<></>)}
        </Wrapper>
    )
}

SearchBox.propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    textHolder: PropTypes.string,
    filterTerm: PropTypes.object,
    setFilterTerm: PropTypes.func,
}

SearchBox.defaultProps = {
    textHolder: 'Search...',
    filterTerm: null,
    setFilterTerm: () => { }
}

export default SearchBox;
