import React, { useEffect, useState } from 'react';
import siteApi from '../api/siteApi';

const initSearchTerm = {
    mode: 'Sites',
    hint: ''
}
export const useSites = (setLoading, clientSelected) => {
    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [error, setError] = useState(false);
    const [listSites, setListSites] = useState([]);

    const getSites = async () => {
        try {
            setError(false);
            setLoading(true);

            /**Call API */
            const params = {
                hint: searchTerm.hint,
                clientID: clientSelected.clientID ?? 0
            }
            const sites = await siteApi.getAll(params);
            console.log(sites.data);
            setListSites(sites.data.dtSite || []);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSites();
        return;
    }, [searchTerm])

    return { searchTerm, setSearchTerm, listSites };
}

