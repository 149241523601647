import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { GoodsInfo, SalesGoods, SHeader, Wrapper } from './styles';
import SalesGoodsItem from '../SalesGoodsItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import TwitterGoodsItem from '../TwitterGoodsItem';


const SalesSlipsOfGoods = ({ salesGoods, twitterGoods }) => {
    const [showSalesGoods, setShowSalesGoods] = useState(true);
    const [showGoodsInfo, setShowGoodsInfo] = useState(true);
    console.log(twitterGoods);
    const handleShowSalesGoods = (e) => {
        console.log(e.target.checked);
        setShowSalesGoods(e.target.checked);
    }

    const handleGoodsInfo = (e) => {
        setShowGoodsInfo(e.target.checked);
    }

    return (
        <Wrapper>
            <SHeader>
                <label>伝票</label>
                <input hidden id="id_showSalesGoods" type="checkbox" onClick={handleShowSalesGoods} checked={showSalesGoods} />
                {showSalesGoods ? <label htmlFor="id_showSalesGoods" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showSalesGoods" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            <SalesGoods hidden={showSalesGoods}>
                {salesGoods.length > 0 ? salesGoods.map(item => (
                    <SalesGoodsItem key={item.SalesGoodsID} salesGoodsItem={item} />
                )) : <label>データなし</label>}
            </SalesGoods>
            <SHeader className="border-top-3">
                <label>商品情報</label>
                <input hidden id="id_showGoodsInfo" type="checkbox" onClick={handleGoodsInfo} checked={showGoodsInfo} />
                {showGoodsInfo ? <label htmlFor="id_showGoodsInfo" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showGoodsInfo" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            <GoodsInfo hidden={showGoodsInfo}>
                {twitterGoods.length > 0 ? twitterGoods.map(item => (
                    <TwitterGoodsItem key={item.TwitterID} twitterGoodsItem={item} />
                )) : <label>データなし</label>}
            </GoodsInfo>
        </Wrapper >
    )
}


SalesSlipsOfGoods.propTypes = {
    salesGoods: PropTypes.array,
    twitterGoods: PropTypes.array,
}

SalesSlipsOfGoods.defaultProps = {
    salesGoods: [],
    twitterGoods: []
}

export default SalesSlipsOfGoods
