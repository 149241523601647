import React from 'react';
import PropTypes from 'prop-types';
import { SHeader, STable, Wrapper } from './styles';
import { Link } from 'react-router-dom';

const Summary = ({ goodsStock, goodsId, goodsSubCount }) => {

    return (
        <Wrapper>
            <SHeader>
                <span>概要</span>
                {goodsSubCount > 0 ? (<Link to={`/goods/${goodsId}/sub`} className='btn'>サブ</Link>) : null}
            </SHeader>
            <STable>
                <table>
                    <thead>
                        <tr>
                            <th>区分</th>
                            <th>価格（P）</th>
                            <th>発行</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goodsStock.map(stock => (
                            <tr key={stock.ServiceTypeID}>
                                <td>{stock.ServiceType}</td>
                                <td className='align-right'>{Intl.NumberFormat('ja-JP').format(stock.Price)}</td>
                                <td>{stock.DatePublished.substring(0, 10) || '--'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </STable>
        </Wrapper>
    )
}


Summary.propTypes = {
    goodsId: PropTypes.string,
    goodsStock: PropTypes.array,
    goodsSubCount: PropTypes.number,
}

Summary.defaultProps = {
    goodsId: '999',
    goodsStock: [],
    goodsSubCount: 0,
}

export default Summary
