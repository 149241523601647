import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ImgLogo from '../../assets/images/logo.png'
import { useLogin } from "../../hooks/useLogin";
import { Wrapper, Content, Logo } from './styles'

const Login = ({ setLoading }) => {
    const { setLoginInfo, isLogin } = useLogin(setLoading);
    const [userID, setUserID] = useState('');
    const [uPassword, setUPassword] = useState('');
    const history = useHistory();
    function handleOnSubmit() {
        if (!userID) {
            alert('ユーザーIDに入力してください。');
            return;
        }
        if (!uPassword) {
            alert('パスワードに入力してください。');
            return;
        }
        const loginInfo = {
            user_id: userID,
            password: uPassword
        };
        setLoginInfo(loginInfo);
    }

    useEffect(() => {
        if (isLogin) {
            history.push('/');
        }
        return;
    }, [isLogin]);

    return (
        <Wrapper>
            <Content>
                <Logo src={ImgLogo} alt='Logo' />
                <input type='text'
                    placeholder='ユーザーID'
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />
                <input type='password'
                    placeholder='パスワード'
                    autoComplete='on'
                    value={uPassword}
                    onChange={(e) => setUPassword(e.target.value)}
                />
                <button type='submit' onClick={handleOnSubmit} >ログイン</button>
            </Content>
        </Wrapper>
    );
};

export default Login;
