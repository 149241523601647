import React from 'react';
import PropTypes from 'prop-types';
import { Header, GoodsList, Wrapper } from './styles';
import GoodsItem from '../GoodsItem';


const GoodsListOfSite = ({ goodsList }) => {
    return (
        <Wrapper>
            <Header>売上・受注</Header>
            <GoodsList>
                {goodsList.map((goodsItem) => {
                    return (<GoodsItem key={goodsItem.SalesGoodsID} goodsItem={goodsItem} />)
                })}
            </GoodsList>
        </Wrapper>
    )
}

GoodsListOfSite.propTypes = {
    goodsList: PropTypes.array,
}

GoodsListOfSite.defaultProps = {
    goodsList: []
}
export default GoodsListOfSite;
