import styled from 'styled-components';

export const Wrapper = styled.div`
    
    a{
        display:flex;
        flex-direction: column;
        border-bottom: 2px solid #ececec;
        color: var(--black);
        text-decoration: none;
        
        span {
            font-size:var(--fontSSmall);
        }
        small {
            padding: 0 10px;
        }
        .title {
            font-size:var(--fontNormal);
            padding-left: 5px;
            color:var(--morienGreen);
            font-weight:bold;
        }
        
        
        .pl-30 {
            padding-left: 30px;
        }

        :hover{
            background-color: #ececec;
            cursor:pointer;
        }

    
    }
`;