import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Wrapper } from './styles';

const GoodsSubItem = ({ objGoodsSub }) => {
    return (
        <Wrapper>
            <Link to={`#`}>
                <div className='title'>
                    {objGoodsSub.GoodsSubName}
                </div>
                <div>
                    <label >発効：</label>{objGoodsSub.DatePublished || '－'}
                    <label className='pl-30'>失効：</label>{objGoodsSub.DateLimit || '－'}
                </div>
                <div>
                    <label >かな：</label>{objGoodsSub.GoodsSubKana}
                </div>
            </Link>
        </Wrapper>
    )
}

GoodsSubItem.propTypes = {
    objGoodsSub: PropTypes.object.isRequired,
}

export default GoodsSubItem
