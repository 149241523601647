import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Wrapper, MorienLogo, Menu } from './styles';

import ImgLogo from '../../assets/images/logo.png';

const NavMenu = ({ clientSelected, setClientSelected }) => {
    const location = useLocation();
    const isPageLogin = location.pathname.includes('/login');
    function handleClick() {
        setClientSelected({
            clientName: "",
            clientID: 0
        })
    }
    if (isPageLogin) return (<></>);
    return (
        <Wrapper>
            <MorienLogo>
                <img src={ImgLogo} alt='Morien-Logo' />
                {/* <span className='staff-name'>スタッフ名スタッフ名フ名スタッフ名</span> */}
            </MorienLogo>
            <Menu>
                <ul>
                    <li><NavLink to='/clients' onClick={handleClick}>お客様一覧</NavLink></li>
                    <li><NavLink to='/salesslips'>注文履歴</NavLink></li>
                    <li><NavLink to='/goods'>商品一覧</NavLink></li>
                    <li><NavLink to='/sites'>現場一覧</NavLink></li>
                </ul>
                {/* <div>Logout</div> */}
            </Menu>
        </Wrapper>
    )
}

export default NavMenu;
