import React, { useEffect, useRef, useState } from 'react';
import loginApi from '../api/loginApi';
import Common from '../Common';

const initLoginInfo = {
    user_id: '',
    password: ''
}
export const useLogin = (setLoading) => {
    const [loginInfo, setLoginInfo] = useState(initLoginInfo);
    const [isLogin, setIsLogin] = useState(false);
    const [error, setError] = useState(false);
    const isFirstLoad = useRef(true);
    const login = async () => {
        try {
            setError(false);
            setLoading(true);
            /**Call Api */
            const dtResponse = await loginApi.login(loginInfo);
            if (dtResponse.data['X-Token']) {
                Common.localStorageSetToken(dtResponse.data['X-UserID'], dtResponse.data['X-Token']);
                setIsLogin(true);
            } else {
                //TODO: ERROR
                console.log(dtResponse);
                alert('ログインが失敗しました！');
            }
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
        }

        login();
        return;
    }, [loginInfo])

    return { setLoginInfo, isLogin };
}

