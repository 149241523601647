import Common from "./Common"

const Auth = {
    isLogin: () => {
        const { xUserID, xToken } = Common.localStorageGetToken();
        if (!xUserID || !xToken) return false;

        return true;
    }
}

export default Auth
