import React from 'react';
import { Wrapper } from './styles';

const GoodsItem = ({ goodsItem }) => {
    return (
        <Wrapper>
            <div className='title'>
                <label>品名：</label>{goodsItem.GoodsName || '-'}
            </div>
            <div>
                <label>数量：</label>{goodsItem.Number || '-'}
            </div>
            <div>
                <label>受注日：</label>{goodsItem.AcceptedDate.substring(0, 10) || '-'}
                <label style={{ marginLeft: '15px' }}>売上日：</label>{goodsItem.SalesDate.substring(0, 10) || '-'}
            </div>
            <div>
                <label>配送：</label>{goodsItem.DeliveryMemo || '無し'}
            </div>
            <div>
                <label>メモ：</label>{goodsItem.NoteMemo || '無し'}
            </div>
        </Wrapper>
    )
}

export default GoodsItem;
