import React, { useEffect } from 'react';
import SearchBox from '../../components/SearchBox';
import { useSites } from '../../hooks/useSites';
import SiteItem from './components/SiteItem';
import { Wrapper, Header, Content } from './styles';


const Sites = ({ setLoading, clientSelected }) => {
    const { searchTerm, setSearchTerm, listSites } = useSites(setLoading, clientSelected);
    const refTop = React.createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [listSites])

    return (
        <Wrapper>
            <SearchBox setSearchTerm={setSearchTerm} textHolder='現場名、お客様名' clientName={clientSelected.clientName} />
            <Content ref={refTop}>
                {listSites.map((objSite) => (
                    <SiteItem
                        key={objSite.SiteID}
                        objSite={objSite}
                    />
                ))}
            </Content>
        </Wrapper>
    )
}

export default Sites;
