import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderDetails from '../../components/HeaderDetails';
import { useSiteDetails } from '../../hooks/useSiteDetails';
import GoodsListOfSite from './components/GoodsListOfSite';

import { Wrapper, Content, SiteInfo } from './styles';
const SiteDetails = ({ setLoading }) => {
    const { site_id } = useParams();
    const { siteID, setSiteID, siteDetails } = useSiteDetails(setLoading);

    useEffect(() => {
        setSiteID(site_id);
        return;
    }, [])


    return (
        <>
            {
                siteDetails.length < 1 ? (<p> データ無し</p>)
                    : (
                        <Wrapper>
                            <HeaderDetails mTitle={siteDetails.SiteName} subTitle={siteDetails.SiteTypeName} />
                            <Content>
                                <SiteInfo>
                                    <div>
                                        <label>住所：</label>
                                        〒{siteDetails.PostalCode || ''}　{siteDetails.AddressPrefecture || ''}{siteDetails.AddressCity || ''}{siteDetails.AddressNumber || ''}
                                    </div>
                                    <div>
                                        <label>電話：</label>
                                        {siteDetails.Tel || '--'}
                                    </div>
                                    <div>
                                        <label>開始日：</label>{siteDetails.DateBeginning.substring(0, 10) || '--'}
                                        <label style={{ marginLeft: '20px' }}>最終日：</label>{siteDetails.DateFinished.substring(0, 10) || '--'}
                                    </div>
                                    <div>
                                        <label>お客様：</label>{siteDetails.ClientName}
                                    </div>
                                    <div>
                                        <label>担当支店：</label>{siteDetails.ChargeObjectName}
                                    </div>
                                    <div>
                                        <label>メモ：</label>{siteDetails.SiteMemo}
                                    </div>
                                </SiteInfo>
                                {/* ANCHOR: SalesSlipsList Of Site */}
                                <GoodsListOfSite goodsList={siteDetails.SalesGoods || []} />
                            </Content>
                        </Wrapper>
                    )
            }
        </>
    )
}

export default SiteDetails
