import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderDetails from '../../components/HeaderDetails';
import { useGoodsDetails } from '../../hooks/useGoodsDetails';
import SalesSlipsOfGoods from './components/SalesSlipsOfGoods';
import Summary from './components/Summary';

import { Wrapper, Content } from './styles';

const GoodsDetails = ({ setLoading }) => {
    const { goods_id } = useParams();
    const { goodsID, setGoodsID, goodsDetails } = useGoodsDetails(setLoading);
    useEffect(() => {
        setGoodsID(goods_id);
        return;
    }, [])
    return (
        <Wrapper>
            <HeaderDetails mTitle={goodsDetails.GoodsName || ''} subTitle='' />
            <Content>
                <Summary goodsStock={goodsDetails.GoodsStock || []}
                    goodsSubCount={parseInt(goodsDetails.GoodsSubCount)}
                    goodsId={goodsID}
                />
                <SalesSlipsOfGoods salesGoods={goodsDetails.SalesGoods || []} twitterGoods={goodsDetails.TwitterGoods || []} />
            </Content>
        </Wrapper>
    )
}

export default GoodsDetails;
