import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import { Link } from 'react-router-dom';

const ClientItem = ({ objClient, setClientSelected }) => {
    function handleClick() {
        setClientSelected({
            clientID: objClient.ClientID,
            clientName: objClient.ClientName
        });
    }
    return (
        <Wrapper>
            <Link to={`/salesslips`} onClick={handleClick}>
                <span className='title'>{objClient.ClientName}</span>
                <small >〒{objClient.PostalCode}</small>
                <small>{objClient.Pref + objClient.Adr}</small>
                <small>最終購入日：{objClient.LastSalesDate ? objClient.LastSalesDate.slice(0, 10) : '－不定－'}</small>
            </Link>
        </Wrapper>
    )
}

ClientItem.propTypes = {
    objClient: PropTypes.object.isRequired,
}

export default ClientItem;
