import axiosClient from "./axiosClient";

const goodsApi = {
    getAll(params) {
        const url = '/goods';
        return axiosClient.get(url, { params });
    },

    getDetails(params) {
        const url = '/goods/details';
        return axiosClient.get(url, { params });
    },

    getGoodsSubs(params) {
        const url = '/goods/sub';
        return axiosClient.get(url, { params });
    }
}

export default goodsApi;
