import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import { Link } from 'react-router-dom';

const SalesSlipItem = ({ objSalesSlip }) => {
    return (
        <Wrapper>
            <Link to={`/salesslips/${objSalesSlip.SalesSlipID}`}>
                <div className='title'>
                    {objSalesSlip.ClientName}
                </div>
                <small>
                    <span><label>現場：</label> {objSalesSlip.SiteName}</span>
                </small>
                <small>
                    <span><label>受注日：</label>{objSalesSlip.AcceptedDate ? objSalesSlip.AcceptedDate.slice(0, 10) : '－'}</span>
                    <span className='pl-30'><label>納品日：</label>{objSalesSlip.DeliveryDate ? objSalesSlip.DeliveryDate.slice(0, 10) : '－'}</span>
                </small>
                <small>
                    <span>
                        <label>商品：</label>{objSalesSlip.GoodsFirst}
                        <label className='pl-30'>数：</label>{objSalesSlip.GoodsCount}
                    </span>
                </small>
            </Link>
        </Wrapper>
    )
}

SalesSlipItem.propTypes = {
    objSalesSlip: PropTypes.object.isRequired,
}

export default SalesSlipItem;
