import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBox from '../../components/SearchBox';
import { useGoodsSub } from '../../hooks/useGoodsSub';
import GoodsSubItem from './components/GoodsSubItem';
import { Wrapper, Content } from './styles';


const GoodsSub = ({ setLoading }) => {
    const { goods_id } = useParams();
    const { searchTerm, setSearchTerm, filterTerm, setFilterTerm, goodsSubsDisplay } = useGoodsSub(setLoading);
    const refTop = React.createRef();

    useEffect(() => {
        let objSearchTerm = {
            ...searchTerm,
            goodsID: goods_id
        };
        setSearchTerm(objSearchTerm);
        return;
    }, [])


    /**Scroll to Top */
    useEffect(() => {
        // set
        refTop.current.scrollTo(0, 0);

        return;
    }, [goodsSubsDisplay])

    return (
        <Wrapper>
            <SearchBox setSearchTerm={setSearchTerm} textHolder='商品サブ名' filterTerm={filterTerm} setFilterTerm={setFilterTerm} />
            <Content ref={refTop}>
                {goodsSubsDisplay.map((objGoodsSub) => (
                    <GoodsSubItem
                        key={objGoodsSub.GoodsSubID}
                        objGoodsSub={objGoodsSub}
                    />
                ))}
            </Content>
        </Wrapper>
    )
}
export default GoodsSub
