import { useEffect, useState } from 'react';
import goodsApi from '../api/goodsApi';

export const useGoodsDetails = (setLoading) => {
    const [error, setError] = useState(false);
    const [goodsID, setGoodsID] = useState('');
    const [goodsDetails, setGoodsDetails] = useState({});

    const getGoodsDetails = async () => {
        try {
            setError(false);
            setLoading(true);
            /**Call Api */
            const params = {
                id: goodsID,
            }
            const dtRes = await goodsApi.getDetails(params);
            console.log(dtRes);
            setGoodsDetails(dtRes.data || {})
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!goodsID) {
            return;
        }
        getGoodsDetails();
        return;
    }, [goodsID])

    return { goodsID, setGoodsID, goodsDetails };
}

