import { useEffect, useState } from 'react';
import salesSlipApi from '../api/salesSlipApi';

const initSearchTerm = {
    mode: 'SalesSlips',
    hint: ''
}
export const useSalesSlips = (setLoading, clientSelected) => {
    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [error, setError] = useState(false);
    const [listSalesSlips, setListSalesSlips] = useState([]);
    console.log(searchTerm);
    const getSalesSlips = async () => {
        try {
            setError(false);
            setLoading(true);

            /**Call Api */
            const params = {
                hint: searchTerm.hint,
                clientID: clientSelected.clientID ?? 0
            }
            const salesSlips = await salesSlipApi.getAll(params);
            console.log(salesSlips.data);
            setListSalesSlips(salesSlips.data.dtSalesSlip || []);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSalesSlips();
        return;
    }, [searchTerm])

    return { searchTerm, setSearchTerm, listSalesSlips };
}

