import { useEffect, useState } from 'react';
import goodsApi from '../api/goodsApi';

const initSearchTerm = {
    mode: 'GoodsSub',
    goodsID: '',
    hint: ''
}

const initFilterTerm = {
    dateLimit: 'GoodsSub',
    enable: false,
}

export const useGoodsSub = (setLoading) => {
    const [error, setError] = useState(false);

    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [listGoodsSub, setListGoodsSub] = useState([]);
    const [goodsSubsDisplay, setGoodsSubsDisplay] = useState([]);

    const [filterTerm, setFilterTerm] = useState(initFilterTerm);

    const getlistGoodsSub = async () => {
        try {
            setError(false);
            setLoading(true);
            /**Call Api */
            const params = {
                goods_id: searchTerm.goodsID,
                hint: searchTerm.hint,
            }
            const dtRes = await goodsApi.getGoodsSubs(params);
            console.log(dtRes);
            setListGoodsSub(dtRes.data.GoodsSubs || []);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!searchTerm.goodsID) {
            return;
        }
        getlistGoodsSub();
        return;
    }, [searchTerm])


    // Filter GoodsSubs
    useEffect(() => {
        if (filterTerm.enable) {
            // filter
            const filterResult = listGoodsSub.filter(goodsSub => {
                return (goodsSub.DatePublished === goodsSub.DateLimit) || (goodsSub.DateLimit >= filterTerm.dateLimit)
            })
            console.log(filterResult);
            setGoodsSubsDisplay(filterResult);
        } else {
            setGoodsSubsDisplay(listGoodsSub);
        }

        return;
    }, [listGoodsSub, filterTerm])

    return { searchTerm, setSearchTerm, filterTerm, setFilterTerm, goodsSubsDisplay };
}

