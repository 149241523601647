import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction:row;
    justify-content: center;
    padding: 20px;
    background-color: beige;
    
    @media screen and (max-width: 768px){
        flex-direction:column;
        align-items:${({ alignTitle }) => alignTitle};
        padding: 15px 20px 5px 20px;
    }
`;

export const MainTitle = styled.div`
    color:var(--morienGreen);
    font-weight:bold;
`;

export const SubTitle = styled.div`
    margin-left: 30px;
    @media screen and (max-width: 768px){
        margin-left:0;
    }
`;