import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SHeader, SalesGoods } from './styles';
import SalesGoodsItem from '../SalsesGoodsItem';

const SalesGoodsList = ({ salesGoodsList }) => {
    return (
        <Wrapper>
            <SHeader>ご注文商品</SHeader>
            <SalesGoods>
                {salesGoodsList.map(item => (
                    <SalesGoodsItem key={item.SalesGoodsID} salseGoodsItem={item} />
                ))}
            </SalesGoods>
        </Wrapper>
    )
}

SalesGoodsList.propTypes = {
    salesGoodsList: PropTypes.array,
}

SalesGoodsList.defaultProps = {
    salesGoodsList: []
}

export default SalesGoodsList
