import axiosClient from "./axiosClient";

const siteApi = {
    getAll(params) {
        const url = '/site';
        return axiosClient.get(url, { params });
    },

    getDetails(params) {
        const url = '/site/details';
        return axiosClient.get(url, { params });
    }
}

export default siteApi;