import React, { useEffect } from 'react';
import SearchBox from '../../components/SearchBox';
import { useSalesSlips } from '../../hooks/useSalesSlips';
import SalesSlipItem from './components/SalesSlipItem';
import { Wrapper, Header, Content } from './styles';


const SalesSlips = ({ setLoading, clientSelected }) => {
    const { searchTerm, setSearchTerm, listSalesSlips } = useSalesSlips(setLoading, clientSelected);
    const refTop = React.createRef();
    /**Scroll to Top */
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [listSalesSlips])

    return (
        <Wrapper>
            <SearchBox setSearchTerm={setSearchTerm} textHolder='現場、商品名、お客様名' clientName={clientSelected.clientName} />
            <Content ref={refTop}>
                {listSalesSlips.map((objSalesSlip) => (
                    <SalesSlipItem
                        key={objSalesSlip.SalesSlipID}
                        objSalesSlip={objSalesSlip}
                    />
                ))}
            </Content>
        </Wrapper>
    )
}

export default SalesSlips;
