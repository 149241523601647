import React, { useEffect } from 'react';
import SearchBox from '../../components/SearchBox';
import { Wrapper, Content } from './styles';

import ClientItem from './components/ClientItem';
import { useClients } from '../../hooks/useClients';

const Clients = ({ setLoading, setClientSelected }) => {
    const { searchTerm, setSearchTerm, listClients } = useClients(setLoading);

    const refTop = React.createRef();
    /**Scroll to Top */

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [listClients])

    return (
        <Wrapper ref={refTop}>
            <SearchBox setSearchTerm={setSearchTerm} textHolder='お客様名' />
            <Content>
                {listClients.map((objClient) => (
                    <ClientItem
                        key={objClient.ClientID}
                        objClient={objClient}
                        setClientSelected={setClientSelected}
                    />
                ))}
            </Content>
        </Wrapper>
    )
}

export default Clients;
