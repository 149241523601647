import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import { Link } from 'react-router-dom';

const GoodsItem = ({ objGoods }) => {
    return (
        <Wrapper>
            <Link to={`goods/${objGoods.GoodsID}`}>
                <span className='title'>{objGoods.GoodsName}</span>
                <small >サイズ：{objGoods.Size || '－'}</small>
                <small >コード：{objGoods.GoodsCode || '－'}</small>
                <small >標準在庫：{objGoods.Shelf || '標準在庫なし'}</small>
            </Link>
        </Wrapper>
    )
}

GoodsItem.propTypes = {
    objGoods: PropTypes.object.isRequired,
}

export default GoodsItem;
